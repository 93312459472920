<template>
  <div>
    <!-- CARD -->
    <!-- *** DOPYT -->
    <v-card
    >
      <!-- btn a select -->
      <v-row class="ma-0 pa-0">
        <!-- btn ADMIN SETTINGS(PRESET) -->
        <v-btn
          v-if="btnAllowed() === true"
          color="primary"
          fab
          top
          right
          absolute
          title="Nastavení dotazů"
          style="top:-25px;right:10px"
          @click="settings_dialog = true"
          >
          <v-icon>
            {{ icons.mdiCog }}
          </v-icon>
        </v-btn>
        <!-- SELECT BOX: preset -->
        <v-col>
          <v-select
            v-model="preset"
            :items="preset_items"
            item-text="name"
            item-value="_id"
            label="Zvolte s čím chcete pomoct..."
            :loading="preset_loading"
            clearable
            @change="presetSelected()"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
          </v-select>
        </v-col>
      </v-row>
      <!-- textarea and btn  -->
      <v-row class="ma-0 pa-0">
        <v-col
          xl="12"
          lg="12"
          sm="12"
          md="12"
          xs="12"
        >
          <div style="position:relative">
            <!-- CONDITION(podmienky) -->
            <v-card-text>
              <!-- description -->
              <div style="font-size:15px;"><b><i>{{ description }}</i></b></div>
              <!-- podmienky -->
              <v-textarea
                v-model="condition"
                label="Vaše doplňující informace k dotazu"
                value=""
                rows="6"
                hint="Zde vložte vaše podmínky k upřesnění dotazu"
                clearable
              ></v-textarea>
            </v-card-text>

            <!-- btn RUN -->
            <v-btn
              fab
              right
              absolute
              large
              title="Zeptej se Máničky...(chatGPT)"
              color="#388E3C"
              style="bottom:60px;right:40px;color:white;"
              :loading="btn_run_loading"
              :disabled="btn_run_loading || !preset"
              @click="getResponse()">
              <v-icon
              large
              >
                {{ icons.mdiPlayOutline }}
              </v-icon>
            </v-btn>
          </div>

        </v-col>
      </v-row>
    </v-card>

    <!-- *** ODPOVED -->
    <v-card style="margin-top:10px;"
      v-if="response && response.length > 0"
      :loading="response_loading"
    >
      <v-row class="ma-0 pa-0">
        <v-col>
          <v-timeline
            dense
            align-top
          >
            <v-timeline-item
              v-for="(item, i) in response"
              :key="i"
              color="#1B5E20"
              :icon="icons.mdiRobotConfusedOutline"
              fill-dot
            >
              <v-card
                :color="cardColor(item, i)"
                dark
              >
                <v-card-title>
                  <v-row>
                    <v-col>
                      <div style="font-size:12px;">
                        <v-icon
                          small
                          style="color:white;"
                        >
                          {{ icons.mdiClockOutline }}
                        </v-icon>
                        {{ item.createdon | moment('DD.MM.YYYY HH:mm:ss') }}
                      </div>
                      <div style="font-size:14px;line-height:18px;color:white;padding-left:18px;margin-top: 5px;">Mánička povídá...</div>
                    </v-col>

                    <!-- btn: COPY -->
                    <v-col style="text-align:right">
                      <v-btn
                        icon
                        title="Zkopírovat odpověď Maničky do schránky"
                        @click="copyToClipboard">
                        <v-icon>{{icons.mdiContentCopy}}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-subtitle>
                  <!-- <p>
                    {{ item.definition.prompt }}
                  </p> -->
                </v-card-subtitle>
                <!-- stary typ odpovedi -->
                <div v-if="item.response.choices">
                  <v-card-text
                    class="white text--primary"
                    style="padding-top:15px;"
                    v-for="(choice, ii) in item.response.choices"
                    :key="ii"
                  >
                    <p>
                      <strong id="clipboard_source" v-html="handleText(choice.text)"></strong>
                    </p>
                  </v-card-text>
                </div>
                <!-- novy typ odpovedi -->
                <div v-if="item.response.message">
                  <v-card-text
                    class="white text--primary"
                    style="padding-top:15px;"
                  >
                    <p>
                      <strong id="clipboard_source" v-html="handleText(item.response.message.content)"></strong>
                    </p>
                  </v-card-text>
                </div>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <!-- PAGINATION | VYPINAM HISTORIU!!! zatial nekcu -->
      <!-- <v-row v-if="total_pages_responses > 1">
        <v-col center>
          <div class="text-center">
            <v-pagination
              v-model="page_responses"
              :length="total_pages_responses"
              :total-visible="8"
              circle
              @input="getResponsIndex()"
            ></v-pagination>
          </div>
        </v-col>
      </v-row> -->
    </v-card>

    <!-- ***************************** -->
    <!-- DIALOG admin settings(preset) -->
    <v-dialog
      v-model="settings_dialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <v-row>
            <v-col :cols="9">Administrace dotazů(preset)</v-col>
            <v-col class="text-right">
              <v-chip
                class="ma-2"
                :color="color_status"
                label
                outlined
              >
                {{ label_status }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            lazy-validation
          >
            <!-- NAZOV dotazu(preset) -->
            <v-combobox
              ref="combo_preset_name"
              v-model="selected_preset_name"
              :items="preset_items"
              item-text="name"
              item-value="_id"
              label="Vyberte položku"
              :loading="preset_loading"
              placeholder="Vyhledávání..."
              @keyup="checkSaveStatus(null)"
              @change="loadSettingsPreset"
              clearable
              :rules="[
                v => !!v || 'Název je povinná položka!'
              ]"
            >
            </v-combobox>

            <!-- DEFINICIA dotazu(preset) -->
            <v-textarea
              v-model="preset_settings.definition"
              label="Zadejte definici dotazu v JSON formáte"
              value=""
              rows="10"
              hint="V nastavení playground openAI, změňte režim vo view code na JSON..."
              clearable
              :rules="[
                v => !!v || 'Definice musí být vyplněna!'
              ]"
            ></v-textarea>
            <!-- POPIS dotazu pre zamestnanca(preset) -->
            <v-textarea
              v-model="preset_settings.description"
              label="Zadejte popis dotazu pro zaměstnance"
              value=""
              rows="7"
              clearable
            ></v-textarea>
            <!-- TAGY -->
            <v-combobox
              v-model="preset_settings.tags"
              :items="tag_items"
              item-text="name"
              item-value="value"
              chips
              clearable
              label="Označení"
              multiple
              @change="clearNotExists"
              :rules="[
                v => v.length > 0 || 'Zvolte alespoň jedno označení!'
              ]"
            >
              <template v-slot:selection="{ attrs, item, select }">
                <v-chip
                  v-bind="attrs"
                  :input-value="item.value"
                  close
                  @click="select"
                  @click:close="removeTag(item)"
                >
                  <strong>{{ item.name }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <!-- TLACITKA -->
        <v-row style="margin-top:10px">
          <v-col cols="6">
            <v-card-actions
              class="justify-start"
            >
              <!-- btn NOVY -->
              <v-btn
                v-if="this.preset_id"
                fab
                x-small
                @click="newPreset"
                color="#55B256"
                title="Nový"
                style="color:white"
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>

              <!-- btn ODSTRANIT -->
              <v-btn
                v-if="this.preset_id"
                fab
                x-small
                @click="remove_dialog = true"
                color="error"
                title="Odstranit"
              >
                <v-icon>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="6">
            <v-card-actions
              class="justify-end"
            >
              <!-- btn ZRUSIT -->
              <v-btn
                outlined
                rounded
                text
                @click="settings_dialog = false"
                :disabled="settings_loading "
              >
                Zrušit
              </v-btn>

              <!-- btn ULOZIT -->
              <v-btn
                outlined
                rounded
                color="primary"
                @click="savePreset"
                :loading="settings_loading"
                :disabled="btn_save_disabled"
              >
                Uložit
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- remove dialog -->
    <v-dialog
      v-model="remove_dialog"
      max-width="350"
    >
      <v-card>
        <v-card-title class="text-h5">
          Odstranit
        </v-card-title>

        <v-card-text>
          Natrvalo odstranit toto přednastavení?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="remove_dialog = false"
          >
            Zrušit
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="removePreset"
            :loading="remove_loading"
          >
            Odstranit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */
/* eslint no-lonely-if: "off" */
/* eslint prefer-arrow-callback: "off" */
/* eslint object-shorthand: "off" */
/* eslint arrow-parens: "off" */

import { mdiCog, mdiPlayOutline, mdiTagMultipleOutline, mdiRobotConfusedOutline, mdiClockOutline, mdiTrashCanOutline, mdiPlus, mdiContentCopy } from '@mdi/js'
import axios from 'axios'
import response from '@/mixins/response'
import SnackBar from '@/components/snackbar/index.vue'

export default {
  components: {
    'snack-bar': SnackBar,
  },
  mixins: [response],
  data() {
    return {
      snackbar: false,
      snack_color: 'red',
      snack_text: '',

      preset: '',
      preset_id: undefined,
      preset_loading: false,
      preset_items: [],

      remove_dialog: false,
      remove_loading: false,

      preset_settings_name: '',
      selected_preset_name: '',
      preset_settings: {
        name: '',
        definition: '',
        tags: [],
      },

      btn_run_loading: false,
      btn_save_disabled: false,

      response: [],
      response_loading: false,
      page_responses: 1,
      total_pages_responses: 1,
      per_page_responses: 1, // nechcu mat historiu, aspon zatial tak vzdy poslednu odpoved zobraz

      condition: '',

      settings_dialog: false,
      settings_loading: false,

      // Icons
      icons: {
        mdiCog,
        mdiPlayOutline,
        mdiTagMultipleOutline,
        mdiRobotConfusedOutline,
        mdiClockOutline,
        mdiTrashCanOutline,
        mdiPlus,
        mdiContentCopy,
      },

      tag_items: [
        { name: 'CZE', value: '1000' },
        { name: 'SVK', value: '2000' },
      ],

      label_status: 'Nový',
      color_status: '#55B256',

      description: '',

      allow_edit: ['mokranovad', 'krupickap', 'm.kissimon', 'd.tazky@indexnoslus.sk', 'm.kramarcik@indexnoslus.sk', 'z.danislavova@indexnoslus.sk'],
    }
  },
  watch: {
    selected_preset_name: function (new_preset) {
      this.checkSaveStatus(new_preset)
    },
    settings_dialog: function (p_status) {
      if (p_status === false) { // dialog sa zavrel
        this.newPreset()
      }
    },
    snackbar(new_val) {
      if (new_val === true) {
        setTimeout(() => {
          this.snackbar = false
        }, 8000)
      }
    },
  },
  created() {
    console.log('::. OpenAI created...')
    this.loadPreset()

    // this.getResponsIndex(this.$store.getters.getProfile._id) // nechcu mat historiu, aspon zatial tak nenacitaj
  },
  mounted() {},
  methods: {
    removeTag(p_item) {
      this.preset_settings.tags.splice(this.preset_settings.tags.indexOf(p_item), 1)
    },
    clearNotExists(p_tags) {
      for (let index = 0; index < p_tags.length; index++) {
        const tag = p_tags[index]
        if (this.tag_items.indexOf(tag) === -1) {
          this.preset_settings.tags.splice(index, 1)
        }
      }
    },
    loadPreset() {
      this.preset_loading = true
      let config = { // GET
        url: `${process.env.VUE_APP_BASE_API}/api/v2/openai/list`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }

      axios(config)
        .then(resp => {
          this.preset_loading = false
          if ((this.$store.getters.getProfile && this.$store.getters.getProfile.is_admin) || (this.$store.getters.getProfile && this.$store.getters.getProfile.login && this.allow_edit.indexOf(this.$store.getters.getProfile.login) > -1)) {
            this.preset_items = resp.data.db_data
          } else {
            let svk = []
            let cze = []
            for (let index = 0; index < resp.data.db_data.length; index++) {
              const item = resp.data.db_data[index]
              let ii = this.lh.findIndex(item.tags, function (o) { return o.name === 'SVK' })
              if (ii > -1) {
                svk.push(item)
              } else {
                cze.push(item)
              }
            }
            if ((this.$store.getters.getProfile.login === 'slovensko') || ((this.$store.getters.getProfile.login !== 'krupickap') && (this.$store.getters.getProfile.email && this.$store.getters.getProfile.email.indexOf('indexnoslus.sk') > -1))) {
              this.preset_items = svk
            } else {
              this.preset_items = cze
            }
          }
        })
        .catch(err => {
          this.preset_loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    savePreset() {
      let name = this.$refs.combo_preset_name.$refs.input.value
      if (this.$refs.form.validate()) {
        // this.settings_loading = true
        let config = {}
        let body = JSON.parse(JSON.stringify(this.preset_settings))
        body.name = name
        if (this.preset_id) {
          config = { // UPDATE
            url: `${process.env.VUE_APP_BASE_API}/api/v2/openai/${this.preset_id}`,
            data: body,
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'put',
          }
        } else {
          config = { // CREATE
            url: `${process.env.VUE_APP_BASE_API}/api/v2/openai/`,
            data: body,
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'post',
          }
        }
        axios(config)
          .then(resp => {
            this.settings_dialog = false
            this.settings_loading = false
            this.loadPreset()
          })
          .catch(err => {
            this.settings_loading = false
            console.error(err)
            this.snack_text = this.responseError(err)
            this.snackbar = true
          })
      }
    },
    removePreset() {
      if (!this.preset_id) {
        return
      }

      // ---
      this.remove_loading = true
      let config = { // CREATE
        url: `${process.env.VUE_APP_BASE_API}/api/v2/openai//${this.preset_id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'delete',
      }
      axios(config)
        .then(resp => {
          this.remove_dialog = false
          this.remove_loading = false
          this.loadPreset()
          this.newPreset()
        })
        .catch(err => {
          this.remove_loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    newPreset() {
      this.$refs.form.reset()
      this.preset_id = undefined
    },
    loadSettingsPreset() {
      this.btn_save_disabled = true
      if (this.selected_preset_name && this.selected_preset_name._id) {
        let preset_id = this.preset_id || this.selected_preset_name._id
        let index = this.lh.findIndex(this.preset_items, function (o) { return o._id === preset_id })
        if (index > -1) {
          this.preset_settings.definition = this.preset_items[index].definition
          this.preset_settings.description = this.preset_items[index].description
          this.preset_settings.tags = this.preset_items[index].tags
        }
      }
      this.btn_save_disabled = false
    },
    getResponse() {
      if (!this.preset) {
        return
      }
      this.response_loading = true
      this.btn_run_loading = true

      // ---
      let preset = this.preset
      let index = this.lh.findIndex(this.preset_items, function (o) { return o._id === preset })
      let body = JSON.parse(this.preset_items[index].definition)
      body.messages[0].content += ` ${this.condition}`
      let config = { // GET
        url: `${process.env.VUE_APP_BASE_API}/api/v2/openai/get_response`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(body),
        method: 'post',
      }

      axios(config)
        .then(resp => {

          // this.response.push(resp.data.db_data)
          this.getResponsIndex()
          this.btn_run_loading = false
        })
        .catch(err => {
          this.btn_run_loading = false
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    getResponsIndex(p_user_id) {
      this.response_loading = true
      p_user_id = p_user_id || this.$store.getters.getProfile._id
      let config = { // GET
        url: `${process.env.VUE_APP_BASE_API}/api/v2/openai/response/index/${p_user_id}/${this.per_page_responses}/${this.page_responses - 1}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }

      axios(config)
        .then(resp => {
          this.response = resp.data.db_data.result
          this.total_pages_responses = resp.data.db_data.total
          this.response_loading = false
        })
        .catch(err => {
          console.error(err)
          this.response_loading = false
          this.snack_text = this.responseError(err)
          this.snackbar = true
        })
    },
    checkSaveStatus(p_new_preset) {
      if (p_new_preset && p_new_preset._id) {
        this.preset_id = p_new_preset._id
      }

      this.preset_settings_name = this.$refs.combo_preset_name.$refs.input.value

      if (this.preset_id) {
        this.label_status = 'Úprava'
        this.color_status = 'primary'
      } else {
        this.label_status = 'Nový'
        this.color_status = '#55B256'
      }
    },
    cardColor(p_item, p_index) {
      if (p_item && p_item.createdon) {
        const rozdiel_v_hodinach = this.$moment().diff(p_item.createdon, 'hours')
        if (rozdiel_v_hodinach < 1 && p_index === 0) {
          return '#2E7D32' // zelena
        }
      }

      return '#5E35B1' // fialova
    },
    handleText(p_text) {
      return p_text.replace(/^(\n){2}/, '').replace(/\n/g, '<br />')
    },
    btnAllowed() {
      if ((this.$store.getters.getProfile && this.$store.getters.getProfile.is_admin && this.$store.getters.getProfile.is_admin === true) || (this.$store.getters.getProfile && this.$store.getters.getProfile.login && this.allow_edit.indexOf(this.$store.getters.getProfile.login.replace('NOSLUS\\', '')) > -1)) {

        return true
      }

      return false
    },
    presetSelected() {
      let preset = this.preset
      let index = this.lh.findIndex(this.preset_items, function (o) { return o._id === preset })
      if (index > -1) {
        this.description = this.preset_items[index].description
      }
      this.condition = ''
    },
    copyToClipboard() {
      const sourceText = document.getElementById('clipboard_source').innerText
      const tempInput = document.createElement('input')
      tempInput.setAttribute('value', sourceText)
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      this.snack_text = 'Text byl zkopírován do schránky!'
      this.snack_color = 'primary'
      this.snackbar = true
    },
  },
}
</script>
